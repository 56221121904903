<style lang="scss" scoped>
.main {
	background-color: $background_color2;
	height: 100vh;
	overflow: scroll;
	margin-bottom: 0.8rem;

}



.nav_bar {
	position: relative;
	position: fixed;
	left: 0;
	top: 0.88rem;
	width: 100%;
	z-index: 10;
	background-color: #fff;
	padding: 0.2rem 0.4rem;
	box-sizing: border-box;
}

.dropdown_list {
	position: absolute;
	width: 100%;
	background-color: $background_color1;
	overflow: scroll;
}

.dropdown {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 16px;
	background-color: $background_color1;
	margin-top: 0.4rem;

	span {
		.van-icon {
			margin: 0 5px;
			color: $font_color_info;
		}
	}
}

.company span {
	border: none;
}

.dropdown_list {
	background-color: $background_color1;
	height: 200px;
	z-index: 10;
	width: 100%;
	left: 0;
	padding: 0.2rem 0;


	.sore_list,
	.conpany_list {
		margin-left: 0.4rem;
		display: flex;
		flex-direction: column;
		font-size: 16px;

		> div {
			margin: 0.2rem 0;
		}
	}
}

.active_sore {
	color: $color_active_2;
}

::v-deep {
	.van-sidebar-item--select::before {
		background-color: $color_active_2;
	}

	.van-tree-select__item--active {
		i {
			display: none;
		}

		color: $color_active_2;
	}
}

.search_no {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	// align-items: center;
	font-size: 16px;
}
.title_name{
	color: $font_color_main;
}
.van-search{
	padding: 0;
	background: #F7F8FA;
	border-radius: 0.36rem;
	height: 0.72rem;
}
.search_text{
	color:$color_active_5
}
.van-tree-select__nav-item{
	padding: 0.2rem 0.4rem;
}
.van-tree-select__item{
	padding:  0.2rem 0.4rem;
	line-height: normal;
}
.recommend {
	padding: 0 0.4rem;
	margin-top: 2.1rem;
	background-color: $background_color2;
}

.recommend_good{
	width: 100%;
	height: 0.6rem;
	background: rgba(64, 158, 255,0.2);
	border-radius: 0.1rem;
	margin-top: 0.2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 0.2rem;
	font-size: 0.24rem;
	box-sizing: border-box;
	.good_content{
		display: flex;
		justify-content: flex-start;
		align-items: center
	}
	.good_text{
		margin-left: 0.12rem;
	}
}
.recommend_box{
	margin-top: 0.2rem;
	padding: 0.2rem;
	background-color: #fff;
	border-radius: 0.12rem;
}

.recommend_item {
	display: flex;
	.img_box{
		width: 1.6rem;
		height: 1.72rem;
		border-radius: 0.12rem;
		overflow: hidden;
		position: relative;

		img{
			width: 100%;
			height: 100%;
		}
		.img_info{
			width: 100%;
			height: 0.36rem;
			background: rgba(0,0,0,0.4);
			font-size: 0.2rem;
			text-align: center;
			line-height: 0.36rem;
			color: $color_1;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}

	.productInfo {
		flex: 1;
		margin-left: 0.2rem;
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		.name {
			width: 4.5rem;
			font-size: 0.28rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-weight: 700;
		}


		.des_box {
			font-size: 0.2rem;
			color: rgba(0,0,0,0.65);
			flex: 1;
			
			.des_item{
				margin-top: 0.12rem;
				width: 100%;
				display: flex;	
				justify-content: flex-start;
				align-items: center;

				.desc_radius{
					width: 0.1rem;
					height: 0.1rem;
					border: 0.04rem solid #409EFF;	
					border-radius: 50%;
					margin:0.02rem 0.12rem 0 0;
				}
				.des_text{
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
			
				}
				.w_26{
					width: 2.6rem;
				}
				.w_4{
					width: 4rem;
				}
			}
		}
		.price {
				display: flex;
				align-items: baseline;
				font-size: 0.2rem;
				color: $color_active_4;
				line-height: 0.32rem;
		
				.price_info{
					color: $font_color_info;
					margin-left: 0.06rem;
				}
				.price_big{
					font-size: 0.36rem;
					font-weight: 700;
				}
			}
	}
}
.price_goods{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top:0.28rem ;
}
.flex_between{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.flex_align_end{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
}
.title_name{
	color: $font_color_main;
}
.fx_1{
	flex: 1;
}
.buy_btn{
	width: 1.2rem;
	height: 0.4rem;
	background: #409EFF;
	border-radius: 0.2rem;
	font-size: 0.2rem;
	line-height: 0.4rem;
}
.pb_safe{
	padding-bottom: 1rem;
	background-color: $background_color2;

}
.van-list{
	background-color: $background_color2;
}
</style>

<template>
	<div class="main" ref="main">
		<div class="fx_1 pb_safe">
			<top-nav bgColor="#F6F6FB" :showBack="false"><span class="title_name">所有产品</span></top-nav>

			<article class="nav_bar">
				<van-search shape="round" v-model="searchValue" show-action placeholder="请输入搜索关键词" @search="onSearch">
					<template #action>
						<div @click="onSearch" class="search_text">搜索</div>
					</template>
				</van-search>
				<!-- <van-field v-model="searchValue" placeholder="请输入搜索关键词" right-icon="搜索"></van-field> -->
				<!-- 下拉 -->
				<div class="dropdown">
					<div class="insur" @click="isInsurShow" :class="insurShow ? 'active_sore' : ''">
						<span>
							{{ insurProduct }}
							<span class="arrow_down_new" v-if="!insurShow" />
							<span class="arrow_up_new"  v-else />
						</span>
					</div>
					<div class="sore" @click="isLableShow" :class="lableShow ? 'active_sore' : ''">
						<span>
							{{ currenLable }}
							<span class="arrow_down_new" v-if="!lableShow" />
							<span class="arrow_up_new" color="#4BA0F8" v-else />
						</span>
					</div>
					<div class="company" @click="isCompanyShow" :class="companyShow ? 'active_sore' : ''">
						<span>
							{{ currentInsuerName }}
							<span class="arrow_down_new" v-if="!companyShow" />
							<span class="arrow_up_new" color="#4BA0F8" v-else />
						</span>
					</div>
				</div>
				<!-- 下拉列表 -->
				<div class="dropdown_list" v-if="listShow">
					<!-- <van-tree-select v-if="insurShow" :items="items" :active-id.sync="activeId" @click-nav="clickNav"
                        @click-item="clickItem" :main-active-index.sync="activeIndex" height="200px" /> -->

					<van-tree-select v-if="insurShow" :items="items" :active-id.sync="activeId" @click-item="clickItem" :main-active-index.sync="activeIndex" height="200px" />
					<!-- <div class="sore_list" v-if="lableShow">
                        <div :class="lableIndex == index ? 'active_sore' : ''" @click="chooseSore(item, index)"
                            v-for="(item, index) in labelList" :key="index">{{ item.labelName }}</div>
                    </div> -->

					<div class="sore_list" v-if="lableShow">
						<div :class="lableIndex == index ? 'active_sore' : ''" @click="chooseSore(item, index)" v-for="(item, index) in newlabelList" :key="index">{{ item.labelName }}</div>
					</div>

					<div class="conpany_list" v-if="companyShow">
						<div :class="InsuerNmaeIndex == index ? 'active_sore' : ''" @click="chooseInsuer(item, index)" v-for="(item, index) in InsurerNameList" :key="index">{{ item.insurerName }}</div>
					</div>
				</div>
			</article>

			<van-list v-model="loading" :finished="finished" finished-text="没有更多的数据" @load="onLoad">
				<div class="recommend">
				<div v-for="(item,index) in productList" :key="index" class="recommend_box">
					<div class="recommend_item"  v-if="item.proId" >
						<div class="img_box">
							<img :src="item.proPicUrlIndexXcx"/>
							<div class="img_info" v-if="item.productAdv">{{ item.productAdv }}</div>
						</div>
						<div class="productInfo">
							<p class="name" :title="item.proName">{{ item.proName }}</p>
							<div :class="['des_box',{'flex_align_end':item.productContext}]">
								<div class="des_item_box">
									<div class="des_item" v-if="item.featureOne">
										<div class="desc_radius"></div>
										<div :class="['des_text',item.productContext?'w_26':'w_4']">
											<div>{{ item.featureOne }}</div>
										</div>
									</div>
									<div class="des_item" v-if="item.featureTwo">
										<div class="desc_radius"></div>
										<div :class="['des_text',item.productContext?'w_26':'w_4']">
											<div>{{ item.featureTwo }}</div>
										</div>
									</div>
									<div class="des_item" v-if="item.featureThree">
										<div class="desc_radius"></div>
										<div :class="['des_text',item.productContext?'w_26':'w_4']">
											<div>{{ item.featureThree }}</div>
										</div>
									</div>
								</div>
								<div class="price" v-if="item.productContext"><span>￥</span><span class="price_big">{{ item.originPrice.toFixed(2) }}</span><span class="price_info">起</span></div>
							</div>
							<div class="price_goods" v-if="!item.productContext">
								<div class="price" ><span>￥</span><span class="price_big">{{ item.originprice?.toFixed(2)  }}</span><span class="price_info">起</span></div>
								<van-button class="buy_btn"  type="info" color="#409EFF" size="mini" @click="goDetail(item)">立即投保</van-button>
							</div>
						</div>
					</div>
					<div class="recommend_good" v-if="item.productContext">
						<div class="good_content">
							<van-icon name="good-job" color="#409EFF" size="0.4rem"/>
							<div class="good_text">{{ item.productContext }}</div>
						</div>
						<van-button class="buy_btn"  type="info" color="#409EFF" size="mini" @click="goDetail(item)">立即投保</van-button>
					</div>
					
				</div>
			</div>
			</van-list>
		</div>
		<div class="search_no" v-if="productList.length == 0">没有搜索到相关的产品</div>
		<van-overlay :show="showOverlay" @click="closeOverlay" />
	</div>
</template>

<script>
import { NavBar, Icon, TreeSelect, Overlay, List, Search,Button } from 'vant';
import { getProductTypes, getProductTwoTypes, getfindAllInsInsurer, getProductLabelpost, http_selectProductInfoV2, http_selectLabelNameV2 } from '@/request/cps.js';
export default {
	name: 'cpsProduct', // 所有产品
	components: {
		[NavBar.name]: NavBar,
		[Icon.name]: Icon,
		[TreeSelect.name]: TreeSelect,
		[Overlay.name]: Overlay,
		[List.name]: List,
		[Search.name]: Search,
		[Button.name]:Button
	},
	data() {
		return {
			searchValue: '', //搜索关键字
			insurShow: false, //保险产品下拉框的显示
			insurProduct: '全部产品', //保险产品名称
			items: [
				{
					text: '全部保险',
					children: [{ text: '全部保险', id: 1 }],
				},
			], //保险产品
			activeId: 27, //选中一级分类的ID
			activeIndex: 0, //选中一级分类的下标
			oneType: [], //一级分类
			showOverlay: false, //遮罩层
			listShow: false, //下拉总外框
			lableShow: false, //标签显示
			labelList: [{ labelName: '全部标签', id: 1 }],
			lableIndex: 0, //当前选中的标签下表
			currenLable: '全部标签', //当前选中的标签
			companyShow: false, //保险公司显示
			productList: [], //产品列表
			loading: false, //加载完成
			finished: false, //上拉加载完成的状态
			total: 0, //产品总条数
			search: {
				page: 0,
				limit: 10,
			}, //查询产品
			InsurerNameList: [{ insurerName: '全部公司', id: 0 }], //保险公司数组
			InsuerNmaeIndex: 0, //选中保险公司的下表
			currentInsuerName: '全部公司', //选中保险公司的名称
			newlabelList: [],
			cpsLoadQuery: {
				proTypeId: '',
				labelId: '',
			},
		};
	},
	created() {
		this.getselectLabelName();
	},
	methods: {
		onSearch() {
			this.search.proName = this.searchValue;
			this.search.page = 0;
			this.activeIndex = 0;
			this.insurProduct = '全部产品';
			this.lableIndex = 0;
			this.currenLable = '全部标签';
			this.InsuerNmaeIndex = 0;
			this.currentInsuerName = '全部公司';
			delete this.search.proTypeId;
			delete this.search.labelId;
			delete this.search.insurerId;
			this.getProduct();
		},
		// 搜索产品
		async getProduct() {
			this.productList = [];
			this.total = 0;
			this.search.page = 0;
			if (this.insurProduct == '全部保险') {
				delete this.search.proTypeId;
			}
			if (this.searchValue == '') {
				delete this.search.proName;
			}
			this.scrollLine();
			this.finished = false;
		},

		async getselectLabelName(index) {
			// 无index加载一遍
			const res = await http_selectLabelNameV2(this.cpsLoadQuery);
			if (!index) {
				res.levelName.forEach(item => {
					item.text = item.level1Name;
					if (item.children) {
						let allId = item.children
							.map(v => {
								v.text = v.level2Name;
								return v.proTypeId;
							})
							.join(',');
						item.children.unshift({
							text: `全部${item.text}`,
							proTypeId: allId,
						});
					}
				});
				this.items = res.levelName;
				this.items.unshift({
					children: [{ text: '全部保险', proTypeId: 1 }],
					text: '全部保险',
				});
			}
			// console.log(res.labelName,'res.labelName');
			this.newlabelList = res.labelName.map(item => {
				return {
					labelName: item.labelName,
					id: item.labelId,
				};
			});
			this.newlabelList.unshift({ labelName: '全部标签', id: 1 });
			// console.log(res,'res.data.insurerName');
			let allID = res.insurerName
				.map(item => {
					return item.insurerId;
				})
				.join(',');

			this.InsurerNameList = res.insurerName.map(item => {
				return {
					insurerName: item.insurerName,
					id: item.insurerId,
				};
			});

			this.InsurerNameList.unshift({ insurerName: '全部公司', id: allID });
			// InsurerNameList: [{ insurerName: '全部公司', id: 0 }],//保险公司数组
		},

		// 产品筛选下拉菜单
		isInsurShow() {
			this.showOverlay = true;
			this.lableShow = false;
			this.companyShow = false;
			if (this.insurShow) {
				this.insurShow = false;
				this.listShow = false;
				this.showOverlay = false;
			} else {
				this.listShow = true;
				this.insurShow = true;
				// this.getTypes()
			}
		},

		// 产品标签筛选下拉菜单
		isLableShow() {
			this.showOverlay = true;
			this.companyShow = false;
			this.insurShow = false;
			if (this.lableShow) {
				this.lableShow = false;
				this.listShow = false;
				this.showOverlay = false;
			} else {
				this.lableShow = true;
				this.listShow = true;
				// this.getProductLabel()
			}
		},

		// 保险公司筛下拉菜单
		isCompanyShow() {
			this.showOverlay = true;
			this.lableShow = false;
			this.insurShow = false;
			if (this.companyShow) {
				this.companyShow = false;
				this.listShow = false;
				this.showOverlay = false;
			} else {
				// this.getInsurerName()
				this.listShow = true;
				this.companyShow = true;
			}
		},

		// 获取保险公司名称
		async getInsurerName() {
			if (this.InsurerNameList.length > 1) {
				return;
			}
			const res = await getfindAllInsInsurer();
			this.InsurerNameList.push(...res.insurerList);
		},

		// 保险产品的二级名称点击事件
		async clickItem(e) {
			this.insurProduct = e.text;
			this.search.page = 0;

			this.lableIndex = 0;
			this.currenLable = '全部标签';
			this.search.labelId = '';

			this.search.insurerId = '';
			this.InsuerNmaeIndex = 0;
			this.currentInsuerName = '全部公司';
			if (e.proTypeId != 1) {
				this.cpsLoadQuery.proTypeId = e.proTypeId;
				this.getselectLabelName(1);
				this.search.proTypeId = e.proTypeId;
				this.getProduct();
			} else {
				// console.log(this.search);
				this.cpsLoadQuery.proTypeId = '';
				this.search.proTypeId = '';
				this.getselectLabelName();
				this.search.limit = 5;
				this.getProduct();
			}
			this.isInsurShow();
		},

		// 选择产品标签
		async chooseSore(item, index) {
			// console.log(item);
			this.currenLable = item.labelName;
			this.lableIndex = index;
			this.listShow = false;
			this.lableShow = false;
			this.showOverlay = false;
			this.cpsLoadQuery.labelId = '';
			this.InsuerNmaeIndex = 0;
			this.currentInsuerName = '全部公司';
			this.search.insurerId = '';
			if (item.id !== 1) {
				this.cpsLoadQuery.labelId = item.id;
				this.search.labelId = item.id;
			} else {
				delete this.search.labelId;
			}
			this.changeInser();
			this.search.page = 0;
			this.getProduct();
		},

		// 保险公司选择按钮
		conpanyClick() {
			this.listShow = false;
			this.companyShow = false;
			this.showOverlay = false;
		},

		async changeInser() {
			const res = await http_selectLabelNameV2(this.cpsLoadQuery);
			// console.log(res);

			let allID = res.insurerName
				.map(item => {
					return item.insurerId;
				})
				.join(',');

			this.InsurerNameList = res.insurerName.map(item => {
				return {
					insurerName: item.insurerName,
					id: item.insurerId,
				};
			});

			this.InsurerNameList.unshift({ insurerName: '全部公司', id: allID });
			// console.log(this.InsurerNameList,'this.InsurerNameList');
		},

		// 选择保险公司
		chooseInsuer(item, index) {
			// console.log(item,'chooseInsuer');
			this.currentInsuerName = item.insurerName;
			this.InsuerNmaeIndex = index;
			if (item.id !== 0) {
				this.search.insurerId = item.id;
			} else {
				delete this.search.insurerId;
			}
			this.search.page = 0;
			this.getProduct();
			this.listShow = false;
			this.companyShow = false;
			this.showOverlay = false;
		},

		// 上拉加载
		async onLoad() {
			const res = await http_selectProductInfoV2(this.search);
			this.productList.push(...res.rows);
			this.search.page++;
			this.total = res.total;
			this.productList.forEach(item => {
				if (item.cpsComboListVo) {
					item.cpsProductContentList = item.cpsComboListVo[0].cpsProductContentVo;
					item.cpsProductContentList = item.cpsProductContentList.filter((v, index) => {
						if (index < 4) {
							return v;
						}
					});
				}
			});

			this.loading = false;
			if (this.productList.length >= this.total) {
				this.finished = true;
			}
		},

		// 去详情页面
		goDetail(item) {
			if (item?.versions === 1 && item?.productCategoryFirst === 2) {
				this.$router.push({ name: 'cpsLiProduct', query: { product: item.proId } });
				return;
			}

			// if (item.proName.indexOf('培训机构责任险') !== -1) {
			if (item.proName.indexOf('“耀守护”培训机构责任险') !== -1) {
				let env = this.getEnv();
				let product;
				if (env === 'dev') {
					product = { product: `56123,71815` };
				} else if (env === 'test') {
					product = { product: `57925,31871` };
				} else if (env === 'pro') {
					product = { product: `56123,71815` };
				}
				this.$store.commit('set_cps_productId', product);
				this.$router.push({ name: 'cps_jiaoPeiJiGou_index', query: product });
				return;
			} else if (item.proName.includes('国任公众')) {
				this.$store.commit('set_cps_productId', item.proId);
				// this.$router.push(`/cps/cpsProductDetail?code=${this.$route.query.code}&productId=${item.proId}`)
				this.$router.push({ name: 'cpsGuoRen_index', query: { productId: item.proId } });
				return;
			} else if (item.proName.includes('马术场地责任')) {
				this.$store.commit('set_cps_productId', item.proId);
				this.$router.push({ name: 'cps_msDuty_index', query: { productId: item.proId } });
				return;
			} else {
				this.$store.commit('set_cps_productId', item.proId);
				this.$router.push(`/cps/cpsProductDetail?code=${this.$route.query.code}&productId=${item.proId}`);
			}
		},

		getEnv() {
			let env = '';
			let devList = ['192.168.', '127.0.0.', 'localhost'];
			let testStr = 'testh5';
			if (devList.some(item => location.hostname.includes(item))) {
				env = 'dev';
			} else if (location.hostname.includes(testStr)) {
				env = 'test';
			} else {
				env = 'pro';
			}
			return env;
		},

		// 关闭遮罩层
		closeOverlay() {
			this.companyShow = false;
			this.insurShow = false;
			this.lableShow = false;
			this.listShow = false;
			this.showOverlay = false;
		},
		// 滚动条
		scrollLine() {
			let top = Math.random() * 10;
			this.$nextTick(() => {
				this.$refs.main.scrollTop = top;
			});
		},
	},
};
</script>
